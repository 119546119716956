import React, {useMemo} from "react"
import {Button, Container, Divider, Stack, Typography} from '@mui/material'
import Drawer from '@mui/material/Drawer';
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {MemberType, UpdateMemberRoleType} from 'types/Members'
import {memberUpdateRoleFormSchema} from 'config/YupSchemaValidations'
import i18n from 'i18next'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {AutocompleteController} from 'components/form/AutocompleteController'
import {memberRoles} from 'config/Options'
import {useUpdateMemberRole} from 'hooks/members/useUpdateMemberRole'
import {useServiceProviders} from "../../hooks/useServiceProviders";


type props = {
    openUpdateMemberRole: any
    setOpenUpdateMemberRole: any
    member: MemberType | undefined
}

export const UpdateMemberRoleDrawer = ({openUpdateMemberRole, setOpenUpdateMemberRole, member}: props) => {
    const {t} = useTranslation();
    const schema = useMemo(() => memberUpdateRoleFormSchema(), [i18n.language]);

    const {UpdateMemberRole} = useUpdateMemberRole()
    const {
        handleSubmit,
        control,
        reset,
        formState: {errors}
    } = useForm<UpdateMemberRoleType>({defaultValues: {role: member?.memberRole}, resolver: yupResolver(schema)})

    const toggleDrawer = () => {
        reset({role: member?.memberRole})
        setOpenUpdateMemberRole(!openUpdateMemberRole)
    }


    const submitHandler = (values: UpdateMemberRoleType) => {
        UpdateMemberRole({
            ...values,
            id: member?.id,
            serviceProviderIds: values.serviceProviderIds?.map((a: any) => a.value)
        })
        setOpenUpdateMemberRole(!openUpdateMemberRole)
    }

    const {serviceProviders} = useServiceProviders()

    const defaultServiceProviderOption: any = useMemo(() => {
        if (member?.serviceProviderIds && member?.serviceProviderIds.length > 0 && serviceProviders && serviceProviders.length > 0) {
            return member?.serviceProviderIds.map(id => {
                const serviceProvider = serviceProviders.find(a => a.id == id)
                return {
                    "label": serviceProvider?.name,
                    "value": serviceProvider?.id
                }
            })
        } else {
            return []
        }
    }, [member?.serviceProviderIds, serviceProviders])


    const serviceProviderIds = useMemo(() => {
        return serviceProviders && serviceProviders.length > 0 ? serviceProviders.map(serviceprovider => ({
            "label": serviceprovider.name,
            "value": serviceprovider.id
        })) : []
    }, [serviceProviders])

    return (
        <Drawer open={openUpdateMemberRole} onClose={toggleDrawer}>
            <Container maxWidth={'sm'} sx={{paddingY: 2}}>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <Typography variant={'h6'}>{t('update_role')}</Typography>
                    <Typography variant={'h5'}>{member?.firstName + ' ' + member?.lastName}</Typography>
                    <Divider variant="fullWidth"/>
                    <Stack direction={'column'} my={2}>
                        <AutocompleteController control={control} name={'role'} defaultValue={member?.memberRole}
                                                label={'select_role'} options={memberRoles}/>
                        <AutocompleteController control={control} name={'serviceProviderIds'} size={'small'}
                                                defaultValue={defaultServiceProviderOption}
                                                label={'service_provider'}
                                                options={serviceProviderIds} multiple={true}/>

                        <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('update')}</Button>
                    </Stack>
                </form>

            </Container>
        </Drawer>
    )
}